import { HttpHeaders, HttpInterceptorFn } from '@angular/common/http'
import { inject } from '@angular/core'
import { environment } from '@environments/environment'
import { from, mergeMap } from 'rxjs'
import { TokenService } from '@lib/services/token/token.service'
import { AppStore } from '@lib/store/AppStore'

const WHITE_LIST = ['run.mocky', 'https://34.111.204.207.nip.io/oidc-okta-1/echo/hello']

export const tokenInterceptor: HttpInterceptorFn = (req, next) => {
	const appStore = inject(AppStore)
	return from(getToken()).pipe(
		mergeMap((token) => {
			// Si la URL de la petición contiene la URL de la API, se añade el token de autenticación
			// if (req.url.includes(environment.apiUrl)) {
			// 	const headers: HttpHeaders = new HttpHeaders({ Authorization: `Bearer ${token}` })
			// 	// const reqClone = req.clone({ headers })
			// 	const reqClone = req.clone({ headers })

			// 	return next(reqClone)
			// }
			// temporal mientras se implementa endpoints del backend
			let headers = new HttpHeaders({})
			const includes = WHITE_LIST.includes(req.url)
			if (!includes) {
				headers = new HttpHeaders({
					Authorization: `Bearer ${token}`,
					endUser: appStore.userInfo()?.bic! || "1",
				})
			}
			req.headers.keys().forEach((k: string) => {
				headers = headers.set(k, req.headers.get(k)!)
			})
			const params = req.params.set("username", appStore.userInfo()?.email!)
			// const reqClone = req.clone({ headers })
			const reqClone = req.clone({ headers, params })
			return next(reqClone)

			// Si la URL de la petición no contiene la URL de la API, se envía la petición sin el token de autenticación
			// return next(req)
		})
	)
}

// Obtiene el token de autenticación de OAuth o Amplify
const getToken = async (): Promise<string> => {
	if (!localStorage.getItem('userSession')) return ''
	const userData = JSON.parse(localStorage.getItem('userSession') || '')
	return userData.opaque_token
	// // Verificamos si el usuario inició sesión con OAuth
	// if (oauthStorage.getItem('id_token')) {
	// 	return oauthStorage.getItem('id_token') ?? ''
	// }

	// // Verificamos si el usuario inició sesión con Amplify
	// const amplifyToken = (await fetchAuthSession()).tokens?.idToken?.toString()

	// return amplifyToken ?? ''
	return ''
}
