import { ApplicationConfig, ErrorHandler, importProvidersFrom } from '@angular/core'
import { provideRouter, withViewTransitions } from '@angular/router'
import { provideHttpClient, withInterceptors } from '@angular/common/http'

import { routes } from './app.routes'
import { tokenInterceptor } from '@lib/interceptors/token.interceptor'
import { GlobalErrorHandler } from '@lib/handlers/global-error.handler'
import { provideFirebaseApp, initializeApp } from '@angular/fire/app'
import { getAuth, provideAuth } from '@angular/fire/auth'
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async'
import { internetConnectionInterceptor } from '@lib/interceptors/internet-connection.interceptor'
import { MessageService } from 'primeng/api'
import { errorInterceptor } from '@lib/interceptors/error.interceptor'
import { BrowserModule } from '@angular/platform-browser'
import { provideAnimations, BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { environment } from '@environments/environment'
import { provideTablerIcons } from 'angular-tabler-icons'
import { IconXboxX, IconCircleCheck, IconPlus } from 'angular-tabler-icons/icons'

export const appConfig: ApplicationConfig = {
	providers: [
		// Servicios básicos
		MessageService,

		// Router y animaciones
		provideRouter(routes, withViewTransitions()),
		provideHttpClient(withInterceptors([internetConnectionInterceptor, tokenInterceptor, errorInterceptor])),
		provideAnimations(),
		BrowserModule,
		BrowserAnimationsModule,
		provideAnimationsAsync(),
		provideAnimationsAsync(), // Usamos la versión asíncrona para optimizar
		provideHttpClient(withInterceptors([internetConnectionInterceptor, tokenInterceptor])),

		// Firebase
		provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
		// provideAnalytics(() => getAnalytics()),
		provideAuth(() => getAuth()),
		// ScreenTrackingService, // Para seguimiento automático de pantallas
		// UserTrackingService, // Para seguimiento de usuarios

		// Iconos
		provideTablerIcons({
			IconXboxX,
			IconCircleCheck,
			IconPlus,
		}),
		// Manejador global de errores
		{
			provide: ErrorHandler,
			useClass: GlobalErrorHandler,
		},
	],
}
