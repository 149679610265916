import { ProgressSpinnerModule } from 'primeng/progressspinner'
import { AuthorizationService } from '@lib/services/authorization/authorization.service'
import { Component } from '@angular/core'
import { RouterOutlet } from '@angular/router'
import { PrimeNG } from 'primeng/config'
import { PrimePreset } from '@lib/config/prime-preset.config'
import { Toast } from 'primeng/toast'
import { AnalyticsService } from '@lib/services/analytics/analytics.service'

@Component({
    selector: 'app-root',
    imports: [RouterOutlet, Toast, ProgressSpinnerModule],
    template: `
		<div [class]="authorizationService.loading ? 'hidden' : ''">
			<router-outlet />
		</div>
		@if (authorizationService.loading) {
			<div class="flex h-[100vh] items-center justify-center">
				<p-progress-spinner
					strokeWidth="8"
					fill="transparent"
					animationDuration=".5s"
					[style]="{ width: '50px', height: '50px' }"
				/>
			</div>
		}
		<p-toast />
	`
})
export class AppComponent {
	constructor(
		private readonly primeConfig: PrimeNG,
		private analyticsService: AnalyticsService,
		readonly authorizationService: AuthorizationService
	) {
		this.primeConfig.theme.set({
			preset: PrimePreset,
			options: {
				darkModeSelector: 'dark-mode',
			},
		})
	}
}
