import { Component } from '@angular/core'
import { RouterOutlet } from '@angular/router'
import { ToastNotificationsPortalComponent } from './components/notifications/toast-notifications-portal/toast-notifications-portal.component'
import { PrimeNG } from 'primeng/config'
import { PrimePreset } from '@lib/config/prime-preset.config'

@Component({
	selector: 'app-root',
	standalone: true,
	imports: [RouterOutlet, ToastNotificationsPortalComponent],
	template: `
		<router-outlet />
		<app-toast-notifications-portal />
	`,
})
export class AppComponent {
	constructor(private readonly primeConfig: PrimeNG) {
		this.primeConfig.theme.set({
			preset: PrimePreset,
			options: {
				darkModeSelector: 'dark-mode',
			},
		})
	}
}
