import { HttpErrorResponse, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http'
import { inject } from '@angular/core'
import { catchError, from, lastValueFrom, Observable } from 'rxjs'
import { OAuthService } from '@lib/services/auth/oauth.service' // Asegúrate de cambiar esto a la ubicación correcta de tu servicio
import { MessageService } from 'primeng/api'
import { Router } from '@angular/router'

export const errorInterceptor: HttpInterceptorFn = (req, next) => {
	const oauthService = inject(OAuthService)
	const messageService = inject(MessageService)
	const router = inject(Router)

	const currentUrl = window.location.pathname

	if (currentUrl === '/auth/login') {
		return next(req)
	}

	return from(handle(req, next)).pipe(
		catchError(async (err: HttpErrorResponse) => {
			// Manejo de error 401 - Cerrar sesión y redirigir al login
			if (err.status === 401) {
				messageService.add({
					severity: 'error',
					life: 10000,
					summary: 'Cierre de sesión',
					detail: 'Su sesión ha expirado.',
				})
				await oauthService.logout(true)
				router.navigate(['/auth/login'])
			}
			throw err
		})
	)
}

const handle = async (req: HttpRequest<any>, next: HttpHandlerFn) => {
	return lastValueFrom(next(req))
}
