import { ApplicationConfig, ErrorHandler, importProvidersFrom } from '@angular/core'
import { provideRouter, withViewTransitions } from '@angular/router'
import { provideHttpClient, withInterceptors } from '@angular/common/http'
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations'

import { routes } from './app.routes'
import { provideHotToastConfig } from '@ngneat/hot-toast'
import { tokenInterceptor } from '@lib/interceptors/token.interceptor'
import { GlobalErrorHandler } from '@lib/handlers/global-error.handler'
import { provideFirebaseApp, initializeApp } from '@angular/fire/app'
import { environment } from '@environments/environment'
import { getAuth, provideAuth } from '@angular/fire/auth'
import { TablerIconsProvider } from 'angular-tabler-icons'
import * as TablerIcons from 'angular-tabler-icons/icons'
import { BrowserModule } from '@angular/platform-browser'
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async'

export const appConfig: ApplicationConfig = {
	providers: [
		provideRouter(routes, withViewTransitions()),
		provideHttpClient(withInterceptors([tokenInterceptor])),
		provideAnimations(),
		BrowserModule,
		BrowserAnimationsModule,
		provideAnimationsAsync(),
		provideHotToastConfig(),
		provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
		provideAuth(() => getAuth()),
		TablerIconsProvider.pick(TablerIcons, {
			ignoreWarnings: true,
		}),
		// {
		// 	provide: ErrorHandler,
		// 	useClass: GlobalErrorHandler,
		// },
	],
}
