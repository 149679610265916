import { inject } from '@angular/core'
import { CanActivateFn, Router } from '@angular/router'
import { OAuthService } from '@lib/services/auth/oauth.service'
import { AppStore } from '@lib/store/AppStore'

export const authGuard: CanActivateFn = async (): Promise<boolean> => {
	const oauthService = inject(OAuthService)
	const router = inject(Router)
	// const isAuth = await oauthService.validateUserSession()
	const isAuth = localStorage.getItem('userSession')
	// Solo permite el acceso a la pagina de inicio si esta autenticado por Auth o por OAuth
	if (isAuth) {
		return true
	}

	// Si no esta autenticado por Auth o por OAuth, redirige a la pagina de login
	router.navigate(['/auth/login'])
	return false
}

export const authLoginPageGuard: CanActivateFn = async (): Promise<boolean> => {
	const oauthService = inject(OAuthService)
	const router = inject(Router)
	// const isAuth = await oauthService.validateUserSession()
	const isAuth = localStorage.getItem('userSession')
	console.log(isAuth)

	// Solo muestra la pagina de login si no esta autenticado por Auth o por OAuth
	if (!isAuth) {
		return true
	}

	// Si esta autenticado por Auth o por OAuth, redirige a la pagina de inicio
	router.navigate(['/home'])
	return false
}
