import { HttpHeaders, HttpInterceptorFn, HttpParams } from '@angular/common/http'
import { environment } from '@environments/environment'
import { from, mergeMap } from 'rxjs'
import { IAuthorization } from '@lib/definitions/sidebar-item.definition'

const WHITE_LIST = [
	'run.mocky',
	`${environment.proxyBaseUrl}/oidc-okta-1/echo/hello`,
	`${environment.proxyBaseUrl}/apigee/portal-managment/refresh-token`,
]

export const tokenInterceptor: HttpInterceptorFn = (req, next) => {
	const force401 = true
	const decoded: IAuthorization = JSON.parse(localStorage.getItem('userSession')!)

	return from(getToken()).pipe(
		mergeMap((token) => {
			let headers = new HttpHeaders({})
			const includes = WHITE_LIST.includes(req.url)
			let params = new HttpParams()
			if (!includes && !force401) {
				headers = new HttpHeaders({
					endUser: decoded?.email! || '1',
				})
			} else if (!includes) {
				headers = new HttpHeaders({
					Authorization: `Bearer ${decoded?.accessToken}`,
					endUser: decoded?.email! || '1',
					'X-CSRF-TOKEN': 'ASEREJE',
					'Content-Security-Policy': "default-src 'self'",
					'X-Frame-Options': 'DENY',
					'X-XSS-Protection': '1; mode=block',
					'Strict-Transport-Security': 'max-age=31536000; includeSubDomains; preload',
					'Transaction-id': 1,
					Secuence: 1,
				})
			}
			params = req.params.set('username', decoded?.email!)
			req.headers.keys().forEach((k: string) => {
				headers = headers.set(k, req.headers.get(k)!)
			})
			const reqClone = req.clone({ headers, params })
			return next(reqClone)
		})
	)
}

// Obtiene el token de autenticación
const getToken = async (): Promise<string> => {
	if (!localStorage.getItem('userSession')) return ''
	const userData = JSON.parse(localStorage.getItem('userSession') || '')
	return userData.opaque_token
	return ''
}
