import { HttpErrorResponse } from '@angular/common/http'
import { ErrorHandler, inject } from '@angular/core'
import { Router } from '@angular/router'
import { environment } from '@environments/environment'

export class GlobalErrorHandler implements ErrorHandler {
	handleError(error: Error): void {
		// Verificamos si el error es una instancia de HttpErrorResponse
		if (error instanceof HttpErrorResponse) {
			// Si el estado es 401 o 403, redirigimos al usuario a la página de login
			if (error.status === 401 || error.status === 403) {
				console.log("??", error)
				const router = inject(Router)

				localStorage.clear()
				router.navigate(['/auth/login'])
			}

			// Colocar aquí para manejar más errores HTTP
		}

		// Colocar aquí para manejar más errores

		// El error solo se imprime en consola si no estamos en producción
		if (environment.production === false) {
			console.error('Error capturado por el GlobalErrorHandler:', error)
		}
	}
}
